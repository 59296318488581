<template>
  <div class="formsAdd">
    <div class="form">
      <el-form
        :rules="dataRules"
        label-width="100px"
        :model="form"
        ref="form"
        v-loading="dataListLoading"
        :class="{
          'form-con-center': form.sendNode == '' || form.sendNode == 1,
        }"
      >
        <!-- <el-form-item label="宣教名称" prop='workName'>
        <el-input placeholder="请输入宣教名称" v-model='form.workName' class='wd400'></el-input>
        </el-form-item>-->
        <el-form-item label="发送节点" prop="sendNode">
          <el-select
            placeholder="请选择发送节点"
            v-model="form.sendNode"
            clearable
            class="wd400"
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="发送条件"
          v-if="form.sendNode && form.sendNode != 1"
        >
          <!-- <el-button type='primary' @click='addMain2'>选择条件</el-button> -->
          <div class="patient-analysis">
            <div class="left">
              <div
                class="out-container"
                :class="[
                  { 'out-container-line marginLeft': treeList.length > 1 },
                  { 'out-container__active': firstIndex == index },
                ]"
                v-for="(item, index) in treeList"
                :key="index"
                @click="clickCurrentContainer(item, index)"
              >
                <div
                  class="out-container-cover"
                  v-if="index == treeList.length - 1"
                ></div>
                <div
                  class="out-relationship-select"
                  v-if="index < treeList.length - 1"
                >
                  <el-select
                    style="width: 60px"
                    v-model="item.selectOutRelation"
                  >
                    <el-option label="与" :value="1"></el-option>
                    <el-option label="或" :value="2"></el-option>
                  </el-select>
                </div>
                <div class="out-container-body">
                  <span
                    class="txt-cut"
                    style="-webkit-box-orient: vertical; padding-right: 10px"
                    :title="item.questionName"
                    >{{
                      item.questionName ? item.questionName : "未命名"
                    }}</span
                  >
                  <span
                    v-if="index > 0"
                    class="delete-btn"
                    @click.stop="deleteOutContainer(item, index)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </div>
              </div>
              <!-- <div
                class="out-container"
                :class="{ marginLeft: treeList.length > 1 }"
                style="border-top: none"
                @click="addOutContainer"
              >
                <div class="out-container-body">
                  <span class="add-item">+新增问卷条件</span>
                </div>
              </div> -->
            </div>
            <div class="right">
              <div class="condition-filter">
                <div class="custom-analysis-filter">
                  <div class="single-analysis">
                    <el-button type="primary" @click="showchoseQuestionDialog"
                      >选择问卷</el-button
                    >
                    <!-- <span>{{questionObj.questionName}}</span> -->
                    <el-button type="text" @click="toDataCollectInfo"
                      >预览问卷</el-button
                    >
                  </div>
                  <div
                    class="out-container2"
                    :class="{ 'out-container-line': analysisList.length > 1 }"
                    v-for="(secondItem, $index) in analysisList"
                    :key="$index"
                  >
                    <div
                      class="out-container-cover"
                      v-if="$index == analysisList.length - 1"
                    ></div>
                    <div
                      class="in-relationship-select"
                      v-if="$index < analysisList.length - 1"
                    >
                      <el-select
                        style="width: 60px"
                        v-model="secondItem.select"
                      >
                        <el-option label="与" :value="1"></el-option>
                        <el-option label="或" :value="2"></el-option>
                      </el-select>
                    </div>
                    <div class="in-relationship-list">
                      <div class="in-container-wrapper">
                        <div
                          class="in-container"
                          :class="{
                            'in-container-line': secondItem.list.length > 1,
                          }"
                          v-for="(ele, i) in secondItem.list"
                          :key="i"
                        >
                          <div
                            class="in-container-cover"
                            v-if="i == secondItem.list.length - 1"
                          ></div>
                          <div
                            class="in-relationship-select"
                            v-if="i < secondItem.list.length - 1"
                          >
                            <el-select
                              style="width: 60px"
                              v-model="ele.require"
                            >
                              <el-option label="与" :value="1"></el-option>
                              <el-option label="或" :value="2"></el-option>
                            </el-select>
                          </div>
                          <div class="in-container-item">
                            <div class="item-con width30">
                              <el-select
                                class="width100"
                                placeholder="请选择问卷题目"
                                v-model="ele.questionInfo"
                                value-key="id"
                                clearable
                                filterable
                                @change="
                                  (val) => {
                                    changeQuestionInfo(val, $index, i, ele);
                                  }
                                "
                              >
                                <el-option
                                  v-for="(item, index) in questionTitleList"
                                  :key="index"
                                  :value="item"
                                  :label="item.options.label"
                                  >{{ item.options.label }}</el-option
                                >
                              </el-select>
                            </div>
                            <div class="item-con width20">
                              <el-select
                                class="width100"
                                placeholder="请选择维度"
                                v-model="ele.type"
                                clearable
                                @change="
                                  (val) => {
                                    changeQuestionType(val, $index, i, ele);
                                  }
                                "
                              >
                                <el-option
                                  v-for="(wd, index) in ele.wdList"
                                  :key="index"
                                  :value="wd.value"
                                  :label="wd.name"
                                  >{{ wd.name }}</el-option
                                >
                              </el-select>
                            </div>
                            <!-- 数值 -->
                            <div
                              v-if="
                                ele.type == 1 &&
                                (ele.questionInfo.type == 'input' ||
                                  ele.questionInfo.type == 'number' ||
                                  ele.questionInfo.type == 'slider' ||
                                  ele.questionInfo.type == 'rate')
                              "
                              class="item-con"
                              style="width: 40%"
                            >
                              <el-select
                                placeholder="请选择比较方式"
                                v-model="ele.sz"
                                clearable
                                style="width: 39%; margin-right: 10px"
                                @change="
                                  (val) => {
                                    changeQuestionSz(val, ele);
                                  }
                                "
                              >
                                <el-option
                                  v-for="(sz, index) in szList"
                                  :key="index"
                                  :value="sz.value"
                                  :label="sz.name"
                                  >{{ sz.name }}</el-option
                                >
                              </el-select>
                              <div
                                style="width: 56%"
                                v-if="ele.sz == 6"
                                class="item-con"
                              >
                                <el-input
                                  style="width: 50%; margin-right: 10px"
                                  placeholder="请输入下限值"
                                  v-model="ele.began"
                                  :min="0"
                                  type="number"
                                ></el-input>
                                <el-input
                                  placeholder="请输入上限值"
                                  v-model="ele.end"
                                  :min="ele.began"
                                  type="number"
                                  style="width: 50%"
                                ></el-input>
                              </div>
                              <el-input
                                v-if="ele.sz && ele.sz != 6"
                                placeholder="请输入数值"
                                v-model="ele.content"
                                style="width: 56%"
                                min="0"
                                type="number"
                              ></el-input>
                            </div>
                            <!-- 内容 -->
                            <div
                              class="item-con"
                              style="width: 40%"
                              v-if="
                                ele.type == 2 &&
                                ele.questionInfo &&
                                (ele.questionInfo.type == 'input' ||
                                  ele.questionInfo.type == 'textarea' ||
                                  ele.questionInfo.type == 'rich-editor')
                              "
                            >
                              <!--  -->
                              <el-select
                                style="width: 39%; margin-right: 10px"
                                placeholder="请选择是否包含"
                                v-model="ele.sz"
                                clearable
                              >
                                <el-option :value="7" label="内容含"
                                  >包含</el-option
                                >
                                <el-option
                                  :value="8"
                                  label="内容不含"
                                ></el-option>
                              </el-select>
                              <el-input
                                v-if="ele.sz"
                                placeholder="请输入关键字"
                                v-model="ele.content"
                                style="width: 56%"
                              ></el-input>
                            </div>
                            <!-- 时间 -->
                            <div
                              class="item-con"
                              style="width: 40%"
                              v-if="
                                ele.type == 1 &&
                                ele.questionInfo &&
                                (ele.questionInfo.type == 'time-range' ||
                                  ele.questionInfo.type == 'time')
                              "
                            >
                              <el-select
                                placeholder="请选择比较方式"
                                v-model="ele.sz"
                                clearable
                                style="width: 39%; margin-right: 10px"
                                v-if="
                                  ele.questionInfo &&
                                  ele.questionInfo.type == 'time'
                                "
                                @change="
                                  (val) => {
                                    changeQuestionSz(val, ele);
                                  }
                                "
                              >
                                <el-option
                                  v-for="(sz, index) in sjList"
                                  :key="index"
                                  :value="sz.value"
                                  :label="sz.name"
                                  >{{ sz.name }}</el-option
                                >
                              </el-select>
                              <el-select
                                placeholder="请选择比较方式"
                                v-model="ele.sz"
                                clearable
                                style="width: 39%; margin-right: 10px"
                                v-if="
                                  ele.questionInfo &&
                                  ele.questionInfo.type == 'time-range'
                                "
                              >
                                <el-option
                                  v-for="(sz, index) in sjList2"
                                  :key="index"
                                  :value="sz.value"
                                  :label="sz.name"
                                  >{{ sz.name }}</el-option
                                >
                              </el-select>
                              <el-time-picker
                                style="width: 56%"
                                v-if="
                                  ele.sz &&
                                  ele.sz != 6 &&
                                  ele.questionInfo.type == 'time'
                                "
                                v-model="ele.content"
                                placeholder="请选择时间"
                                clearable
                                value-format="HH:mm:ss"
                              >
                              </el-time-picker>
                              <!--类型为单个时间条件为介于则为时间框 -->
                              <el-time-picker
                                v-show="
                                  ele.sz &&
                                  ele.questionInfo &&
                                  (ele.questionInfo.type == 'time-range' ||
                                    (ele.questionInfo.type == 'time' &&
                                      ele.sz == 6))
                                "
                                is-range
                                v-model="ele.content"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="选择时间范围"
                                style="width: 56%"
                                value-format="HH:mm:ss"
                              >
                              </el-time-picker>
                            </div>
                            <!-- 日期 -->
                            <div
                              class="item-con"
                              style="width: 40%"
                              v-if="
                                ele.type == 1 &&
                                ele.questionInfo &&
                                (ele.questionInfo.type == 'date' ||
                                  ele.questionInfo.type == 'date-range')
                              "
                            >
                              <el-select
                                placeholder="请选择比较方式"
                                v-model="ele.sz"
                                clearable
                                style="width: 39%; margin-right: 10px"
                                v-if="ele.questionInfo.type == 'date'"
                                @change="
                                  (val) => {
                                    changeQuestionSz(val, ele);
                                  }
                                "
                              >
                                <el-option
                                  v-for="(sz, index) in sjList"
                                  :key="index"
                                  :value="sz.value"
                                  :label="sz.name"
                                  >{{ sz.name }}</el-option
                                >
                              </el-select>
                              <el-select
                                placeholder="请选择比较方式"
                                v-model="ele.sz"
                                clearable
                                style="width: 39%; margin-right: 10px"
                                v-if="ele.questionInfo.type == 'date-range'"
                              >
                                <el-option
                                  v-for="(sz, index) in sjList2"
                                  :key="index"
                                  :value="sz.value"
                                  :label="sz.name"
                                  >{{ sz.name }}</el-option
                                >
                              </el-select>
                              <el-date-picker
                                v-if="
                                  ele.sz &&
                                  ele.sz != 6 &&
                                  ele.questionInfo.type == 'date'
                                "
                                v-model="ele.content"
                                type="date"
                                placeholder="选择日期"
                                style="width: 56%"
                              >
                              </el-date-picker>
                              <el-date-picker
                                style="width: 56%"
                                v-show="
                                  ele.sz &&
                                  (ele.questionInfo.type == 'date-range' ||
                                    (ele.questionInfo.type == 'date' &&
                                      ele.sz == 6))
                                "
                                v-model="ele.content"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                              >
                              </el-date-picker>
                            </div>
                            <!-- 下拉select -->
                            <div
                              class="item-con"
                              style="width: 40%"
                              v-if="
                                ele.type == 3 &&
                                ele.questionInfo &&
                                ele.questionInfo.type == 'select'
                              "
                            >
                              <el-select
                                style="width: 39%; margin-right: 10px"
                                placeholder="请选择是否包含"
                                v-model="ele.sz"
                                clearable
                              >
                                <el-option
                                  :value="11"
                                  label="选项为"
                                ></el-option>
                                <el-option
                                  :value="12"
                                  label="选项不为"
                                ></el-option>
                              </el-select>
                              <el-select
                                style="width: 56%; margin-right: 10px"
                                placeholder="请选择选项"
                                v-model="ele.content"
                                clearable
                                multiple=""
                                v-if="
                                  ele.questionInfo &&
                                  ele.questionInfo.options &&
                                  ele.questionInfo.options.optionItems &&
                                  ele.questionInfo.options.optionItems.length
                                "
                              >
                                <el-option
                                  :value="element.value"
                                  :label="element.label"
                                  v-for="(element, i) in ele.questionInfo
                                    .options.optionItems"
                                  :key="i"
                                  >{{ element.label }}</el-option
                                >
                              </el-select>
                            </div>
                            <!-- 单选 -->
                            <div
                              class="item-con"
                              style="width: 40%"
                              v-if="
                                ele.type == 3 &&
                                ele.questionInfo &&
                                ele.questionInfo.type == 'radio'
                              "
                            >
                              <el-select
                                style="width: 39%; margin-right: 10px"
                                placeholder="请选择是否包含"
                                v-model="ele.sz"
                                clearable
                              >
                                <el-option
                                  :value="11"
                                  label="选项为"
                                ></el-option>
                                <el-option
                                  :value="12"
                                  label="选项不为"
                                ></el-option>
                              </el-select>
                              <el-select
                                style="width: 56%; margin-right: 10px"
                                placeholder="请选择选项"
                                v-model="ele.content"
                                clearable
                                v-if="
                                  ele.questionInfo &&
                                  ele.questionInfo.options &&
                                  ele.questionInfo.options.optionItems &&
                                  ele.questionInfo.options.optionItems.length
                                "
                              >
                                <el-option
                                  :value="element.value"
                                  :label="element.label"
                                  v-for="(element, i) in ele.questionInfo
                                    .options.optionItems"
                                  :key="i"
                                  >{{ element.label }}</el-option
                                >
                              </el-select>
                            </div>
                            <!-- 多选 -->
                            <div
                              class="item-con"
                              style="width: 40%"
                              v-if="
                                ele.type == 3 &&
                                ele.questionInfo &&
                                ele.questionInfo.type == 'checkbox'
                              "
                            >
                              <el-select
                                style="width: 39%; margin-right: 10px"
                                placeholder="请选择是否包含"
                                v-model="ele.sz"
                                clearable
                              >
                                <el-option
                                  :value="11"
                                  label="选项为"
                                ></el-option>
                                <el-option
                                  :value="12"
                                  label="选项不为"
                                ></el-option>
                              </el-select>
                              <el-select
                                style="width: 56%; margin-right: 10px"
                                placeholder="请选择选项"
                                v-model="ele.content"
                                clearable
                                multiple=""
                                v-if="
                                  ele.questionInfo &&
                                  ele.questionInfo.options &&
                                  ele.questionInfo.options.optionItems &&
                                  ele.questionInfo.options.optionItems.length
                                "
                              >
                                <el-option
                                  :value="element.value"
                                  :label="element.label"
                                  v-for="(element, i) in ele.questionInfo
                                    .options.optionItems"
                                  :key="i"
                                  >{{ element.label }}</el-option
                                >
                              </el-select>
                            </div>
                            <!-- <div
                        class="item-con"
                        style="width: 40%"
                        v-if="
                          ele.type == 2 &&
                          ele.questionInfo &&
                          ele.questionInfo.indexType == 7
                        "
                      >
                        <el-select
                          class="width100"
                          style="margin-right: 10px"
                          placeholder="请选择选项"
                          v-model="ele.content"
                          clearable
                          multiple
                        >
                          <el-option
                            :value="element.optionName"
                            v-for="(element, i) in ele.questionInfo.remark"
                            :key="i"
                            >{{ element.optionName }}</el-option
                          >
                        </el-select>
                      </div> -->
                            <div>
                              <span
                                class="delete-btn"
                                @click="deleteSecondContainer($index)"
                                v-if="secondItem.list.length == 1"
                                >删除</span
                              >
                              <span
                                class="delete-btn"
                                @click="deleteInContainer($index, i)"
                                v-else
                                >删除</span
                              >
                            </div>
                          </div>
                        </div>
                        <!-- 新增筛选条件 -->
                        <div class="add-btn" @click="addThirdContainer($index)">
                          + 增加筛选条件
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="add-btn-type2" @click="addOutContainer2">+ 增加组合条件</div> -->
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="内容选择" prop="followUpItem" required>
          <div class="wd400">
            <el-button
              type="primary"
              @click="addMain"
              :disabled="!form.sendNode"
              title="请选择发送节点"
              >选择内容</el-button
            >
          </div>
          <el-table
            class="mt20 wd400"
            v-if="form.followUpItem.length"
            :data="form.followUpItem"
          >
            <el-table-column prop="name" label="已选择内容"></el-table-column>
            <el-table-column
              prop="authorityTypeName"
              label="操作"
              align="right"
              header-align="right"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="del(scope.row)"
                  style="color: #f56c6c"
                  v-if="scope.row.value != 'basic'"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- <div v-else style='display:flex'>{{form.followUpItem.name}}<el-link icon="el-icon-edit" @click='addMain' class='ml20' :underline="false">编辑</el-link></div> -->
        </el-form-item>
        <el-form-item label="配置状态" prop="status" style="width: 500px">
          <el-radio v-model="form.status" :label="1">启用</el-radio>
          <el-radio v-model="form.status" :label="2">禁用</el-radio>
        </el-form-item>
        <el-form-item label="备注" prop="context">
          <el-input
            type="textarea"
            v-model="form.context"
            clearable
            class="wd400"
            placeholder="请输入"
            rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item label="" class="last-el-form-item">
          <el-button type="primary" @click="addForm" v-points>保存</el-button>
          <el-button @click="back">取消</el-button>
        </el-form-item>
      </el-form>
      <choose-form
        v-show="formDialog"
        ref="chooseForm"
        @chooseForm="getOther($event)"
        :type="5"
      ></choose-form>
      <!-- <choose-form v-if='choseQuestionDialog' ref='choseQuestion' @chooseForm='getOther2($event)' :type='5'></choose-form> -->

      <chose-question
        v-if="choseQuestionDialog"
        ref="choseQuestion"
        @setQuestionId="setQuestionId"
        :treeList="treeList"
      ></chose-question>
    </div>
  </div>
</template>
<script>
import Qs from "qs";
import chooseForm from "./components/chooseForm.vue";
// import ChoseQuestion from "../mbscyh/analysis/components/choseQuestion.vue";
import ChoseQuestion from "./components/chooseForm2.vue";
export default {
  name: "formsAdd",

  components: {
    chooseForm,
    ChoseQuestion,
  },

  data() {
    return {
      form: {
        followUpItem: [],
        sendNode: "",
        status: "",
      },
      form2: {
        followUpItem: [],
        sendNode: "",
        status: "",
      },
      dataRules: {
        sendNode: [
          { required: true, message: "请选择发送节点", trigger: "change" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        followUpItem: [
          { required: true, message: "内容不能为空", trigger: "blur" },
        ],
      },
      typeList: [],
      dataListLoading: false,
      formDialog: false,
      formDialog2: false,
      //分析相关
      treeList: [
        {
          selectOutRelation: 1,
          questionName: "",
          analysisList: [
            {
              list: [
                {
                  require: 1,
                  type: "",
                  sz: "",
                  content: "",
                  questionInfo: {},
                },
              ],
            },
          ],
        },
      ],
      analysisList: [
        {
          list: [
            {
              require: 1,
              type: "",
              sz: "",
              content: "",
              questionInfo: {},
            },
          ],
        },
      ],
      currentLength: 1,
      firstIndex: 0, //点击新增左侧
      secondIndex: 0, //点击右侧新增白色边框遮住
      thirdIndex: 0, //点击新增筛选条件出现边框遮线
      choseQuestionDialog: false,
      screeningResultDialog: false, //筛查结果
      questionObj: "",
      selectDateType: 1, //日期类型
      dateTime: "", //具体日期
      dateTime2: "",
      questionTitleList: [], //问卷题目
      wdList: [
        { value: 1, name: "数值" },
        { value: 2, name: "内容" },
        { value: 3, name: "选项" },
      ], //维度列表
      szList: [
        { value: 1, name: "大于" },
        { value: 2, name: "大于等于" },
        { value: 3, name: "等于" },
        { value: 4, name: "小于" },
        { value: 5, name: "小于等于" },
        { value: 6, name: "介于" },
      ], //数值列表
      sjList: [
        { value: 2, name: "早于" },
        { value: 5, name: "晚于" },
        { value: 3, name: "等于" },
        { value: 6, name: "介于" },
      ], //时间列表
      sjList2: [{ value: 6, name: "介于" }], //时间列表
      contentArr: [],
    };
  },

  mounted() {
    this.getTypeList();
  },

  methods: {
    /**
     * 分析搬运
     */

    showScreeningResultDialog() {
      let _this = this;
      if (!_this.questionObj.questionNameId) {
        _this.$message.error("请选择问卷名称");
        return;
      }

      let isNull = true;
      let isBig = false;
      let arr = [];
      let teamsId = "";
      let enertyId = "";
      let libraryId = [];
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (_this.treeList.length == 1) {
        _this.$set(
          _this.treeList[0],
          "analysisList",
          JSON.parse(JSON.stringify(_this.analysisList))
        );
      }
      // console.log(_this.treeList);
      // return;
      _this.treeList.forEach((item, $index) => {
        libraryId.push(item.questionNameId);
        let obj = {
          infoId: item.questionNameId,
          infoName: item.questionName,
          type: 1,
          teamsId: teamsId,
          require: item.selectOutRelation,
          data: [],
        };
        item.analysisList[0].list.forEach((element, index) => {
          element.indexId = "";
          if (element.questionInfo && element.questionInfo.id) {
            element.indexId = element.questionInfo.id;
            element.questionType = element.questionInfo.type;
          }
          if (!element.indexId || !element.type) {
            isNull = false;
            return isNull;
          }
          if (element.type == 1 || element.type == 2) {
            if (!element.sz) {
              isNull = false;
              return isNull;
            } else {
              // if (element.sz) {
              //   element.end = element.began;
              // }
              if (element.sz == 6) {
                if (element.began == "" && element.end == "") {
                  isNull = false;
                  return isNull;
                } else {
                  if (element.began > element.end) {
                    isBig = true;
                    return isBig;
                  } else {
                    element.content = element.began + "," + element.end;
                  }
                }
              }
              if (
                element.content &&
                element.content.length &&
                (element.questionType == "time-range" ||
                  element.questionType == "date-range")
              ) {
                element.content = element.content.join(",");
              }
            }
          }
          if (element.type == 3) {
            if (!element.content) {
              isNull = false;
              return isNull;
            }
            // if (element.questionInfo && element.questionInfo.indexType == 7) {
            //   element.content = JSON.parse(JSON.stringify(element.content));
            // }
          }
          obj.data.push(element);
        });
        arr.push(obj);
      });
      this.contentArr = arr;

      console.log("123", arr);
      // return;

      if (isNull) {
        if (isBig) {
          this.$message.error("下限值必须小于上限值");
          return;
        }
        this.form.contentDetail = JSON.stringify(arr);
        this.form.content = JSON.stringify(arr);
        if (this.form.sendNode && this.form.sendNode != 1) {
          this.submit();
        }
      } else {
        _this.$message.error("请完善所有条件筛选的项目");
      }
    },
    addForm() {
      if (this.form.sendNode && this.form.sendNode != 1) {
        this.showScreeningResultDialog();
      } else {
        this.submit();
      }
    },
    // 预览问卷
    toDataCollectInfo() {
      let _this = this;
      if (!_this.questionObj.questionNameId) {
        _this.$message.error("请选择问卷名称");
        return;
      }
      const href = this.$router.resolve({
        name: "preview",
        query: { id: _this.questionObj.questionNameId },
      });
      window.open(href.href);
    },
    //点击当前的未命名问卷
    clickCurrentContainer(item, index) {
      let _this = this;
      _this.firstIndex = index;
      _this.analysisList = _this.treeList[index].analysisList;
      // _this.secondIndex = _this.analysisList.length - 1;
      // _this.analysisList[0].list.push({
      //   require: 1,
      //   type: "",
      //   sz: "",
      //   content: "",
      //   questionInfo: {},
      // });

      if (item && item.questionNameId) {
        _this.questionObj.questionNameId = item.questionNameId;
        _this.getQustionTitleList(item);
      } else {
        _this.questionTitleList = [];
      }
    },
    //   添加未命名
    addOutContainer(index) {
      let _this = this;
      _this.treeList.push({
        selectOutRelation: 1,
        analysisList: [
          {
            list: [],
          },
        ],
      });
      _this.firstIndex = _this.treeList.length - 1;
      if (_this.firstIndex - 1 == 0) {
        _this.treeList[_this.firstIndex - 1].analysisList = JSON.parse(
          JSON.stringify(_this.analysisList)
        );
      }
      _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
      _this.analysisList[0].list.push({
        require: 1,
        type: "",
        sz: "",
        content: "",
        questionInfo: {},
      });

      _this.secondIndex = _this.analysisList.length - 1;
      _this.currentLength = _this.treeList.length;
      _this.questionObj = {};
      _this.getQustionTitleList();
      console.log("123", _this.treeList);
    },
    // 删除未命名
    deleteOutContainer(item, index) {
      let _this = this;
      _this.treeList.splice(index, 1);
      _this.firstIndex = _this.treeList.length - 1;
      _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
      if (
        _this.treeList[_this.firstIndex] &&
        _this.treeList[_this.firstIndex].questionNameId
      ) {
        _this.questionObj.questionNameId =
          _this.treeList[_this.firstIndex].questionNameId;
        _this.getQustionTitleList(item);
      } else {
        _this.questionTitleList = [];
      }
      let arr = [];
      _this.treeList.forEach((ele) => {
        if (ele.questionNameId) {
          arr.push(ele);
        }
      });
    },
    // 追加右边组合条件
    addOutContainer2() {
      let _this = this;
      _this.treeList[_this.firstIndex].analysisList.push({
        list: [
          {
            require: 1,
          },
        ],
      });
      _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
      _this.secondIndex = _this.analysisList.length - 1;
    },
    // 删除组合条件
    deleteSecondContainer(index) {
      let _this = this;
      // _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
      if (_this.analysisList.length == 1) {
        _this.$message.info("已经到第一个了");
      } else {
        _this.treeList[_this.firstIndex].analysisList.splice(index, 1);
        _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
        _this.secondIndex = _this.analysisList.length - 1;
      }
    },
    // 新增内部筛选条件
    addThirdContainer($index) {
      let _this = this;
      _this.analysisList[$index].list.push({
        require: 1,
        type: "",
        sz: "",
        content: "",
        questionInfo: {},
      });
      // _this.analysisList = JSON.stringify(JSON.parse(_this.analysisList));
      _this.thirdIndex = _this.analysisList[$index].list.length - 1;
      console.log("456", _this.treeList);
    },
    // 删除内部筛选条件
    deleteInContainer($index, i) {
      let _this = this;
      _this.analysisList[$index].list.splice(i, 1);
      // _this.thirdIndex =  _this.analysisList[_this.secondIndex].list.length - 1;
    },
    // 问卷题目选中情况
    changeQuestionInfo(item, pIndex, cIndex, ele) {
      // // 单行文本框可以选择数值和内容
      ele.content = "";
      ele.sz = "";
      ele.type = "";
      if (ele.questionInfo.type == "input") {
        ele.wdList = [
          { value: 1, name: "数值" },
          { value: 2, name: "内容" },
        ];
      }
      if (
        ele.questionInfo.type == "radio" ||
        ele.questionInfo.type == "checkbox" ||
        ele.questionInfo.type == "select"
      ) {
        ele.type = 3;
        ele.wdList = [{ value: 3, name: "选项" }];
      }
      if (
        item.type == "number" ||
        item.type == "slider" ||
        item.type == "rate" ||
        item.type == "time" ||
        item.type == "time-range" ||
        item.type == "date" ||
        item.type == "date-range"
      ) {
        ele.type = 1;
        ele.wdList = [{ value: 1, name: "数值" }];
      }
      if (item.type == "textarea" || item.type == "rich-editor") {
        ele.type = 2;
        ele.wdList = [{ value: 2, name: "内容" }];
      }
    },
    changeQuestionType(item, pIndex, cIndex, ele) {
      ele.sz = "";
      ele.content = "";
    },
    // 改变条件
    changeQuestionSz(item, ele) {
      ele.content = "";
    },
    // 选择问卷显示
    showchoseQuestionDialog() {
      let _this = this;
      _this.choseQuestionDialog = true;
      _this.$nextTick(() => {
        _this.$refs.choseQuestion.init();
      });
    },
    //确定选中问卷
    setQuestionId(value) {
      let _this = this;
      _this.questionObj = value;
      _this.getQustionTitleList();
      _this.$set(
        _this.treeList[_this.firstIndex],
        "questionName",
        _this.questionObj.questionName
      );
      _this.$set(
        _this.treeList[_this.firstIndex],
        "questionNameId",
        _this.questionObj.questionNameId
      );
      // _this.treeList[_this.firstIndex].analysisList[0].list = [
      //   {
      //     require: 1,
      //     type: "",
      //     sz: "",
      //     content: "",
      //     questionInfo: {},
      //   },
      // ];
      let arr = [];
      _this.treeList.forEach((ele) => {
        if (ele.questionNameId) {
          // if (ele.questionNameId == value.questionNameId) {
          //   _this.$message.error('同一个问卷不能多次选择')
          // }
          arr.push(ele);
        }
      });
    },
    getQustionTitleList(item, $index) {
      let _this = this;
      let params = {
        // pageSize: 10000,
        // pageNo: 1,
        // libraryId: _this.questionObj.questionNameId,
        id: _this.questionObj.questionNameId,
			 stringParam1: _this.questionObj.questionNameId,
      };
      if (item && item.questionId) {
        params.libraryId = item.questionId;
      }
      if (params.id && params.id != 1) {
        _this
          .$httpAes({
            url: _this.$httpAes.adornUrl("/vfTableInfo/info"),
            method: "post",
            data: params,
          })
          .then(({ data }) => {
            if (data.status) {
              let widgetList = JSON.parse(data.data.tableContent).widgetList;
              let arr = [];
              if (widgetList.length) {
                widgetList.forEach((ele) => {
                  if (
                    ele.type == "input" ||
                    ele.type == "slider" ||
                    ele.type == "radio" ||
                    ele.type == "checkbox" ||
                    ele.type == "number" ||
                    ele.type == "textarea" ||
                    ele.type == "time" ||
                    ele.type == "time-range" ||
                    ele.type == "date" ||
                    ele.type == "date-range" ||
                    ele.type == "select" ||
                    ele.type == "rate" ||
                    ele.type == "rich-editor"
                  ) {
                    arr.push(ele);
                  }
                });
              }
              _this.questionTitleList = arr;
            }
          });
      } else {
        _this.questionTitleList = [];
      }
    },
    //----------------------结束分析
    check(v) {
      console.log(v);
      if (v == 2) {
        this.$confirm(
          `当前正在进行禁用操作
        \n
        禁用后不会再自动发送，请确认`,
          "禁用",
          {
            confirmButtonText: "确定",
            cancelButtonClass: "btn-custom-cancel",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            console.log("ye");
            this.form.status = 2;
          })
          .catch(() => {
            console.log("2");
            this.form.status = "";
          });
      }
    },
    back() {
      this.$router.back();
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: "发送节点",
		},
       
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    addMain() {
      this.formDialog = true;
      this.$nextTick(() => {
        this.$refs.chooseForm.init(this.form.sendNode);
        if (this.form.followUpItem.length) {
          this.$refs.chooseForm.edit(this.form.followUpItem);
        }
      });
    },
    addMain2() {
      this.formDialog2 = true;
      this.$nextTick(() => {
        this.$refs.chooseForm2.init(this.form.sendNode);
        if (this.form2.followUpItem.length) {
          this.$refs.chooseForm2.edit(this.form2.followUpItem);
        }
      });
    },
    getOther(val) {
      console.log(val, "val gteOther");
      this.form.followUpItem = JSON.parse(JSON.stringify(val));
    },
    getOther2(val) {
      this.form2.followUpItem = JSON.parse(JSON.stringify(val));
    },
    del(row) {
      console.log(this.form.followUpItem);
      console.log(row);
      this.form.followUpItem = this.form.followUpItem.filter((v) => v != row);
    },
    async submit() {
      // this.form.followUpItem.push({name:'基本信息',id:'basic'})
      this.form.teamsId = JSON.parse(localStorage.getItem("currentTeam")).id;
      this.form.hospitalId = JSON.parse(
        localStorage.getItem("currentTeam")
      ).hospitalId;
      if (this.form.followUpItem.length) {
        this.form.followUpItem.forEach((item) => {
          if (item.id == "") {
            item.id = "basic";
          }
        });
      }

      this.form.contentName = this.form.followUpItem
        .map((v) => v.name)
        .join(",");
      this.form.contentIds = this.form.followUpItem.map((v) => v.id).join(",");

      console.log(this.form);

      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          console.log(1);
          delete this.form.followUpItem;
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl("/systemSends/add"),
            method: "post",
            data: this.form,
          });
          console.log(res, "res");
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.$router.back();
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.formsAdd {
  .patient-analysis {
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    .out-container {
      // margin-left: 100px;
      transition: all 0.5s;
      padding: 10px 15px;
      border: 1px solid #e3e3e3;
      height: 70px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      border-top: none;
      &.marginLeft {
        margin-left: 100px;
      }

      &:first-child {
        border-top: 1px solid #e3e3e3;
      }

      .out-relationship-select {
        position: absolute;
        left: -90px;
        top: 50%;
        margin-top: 20px;
      }
      .out-container-body {
        display: flex;
        align-items: center;
        // justify-content: center;
        .add-item {
          color: #0099ff;
        }
        .delete-btn {
          font-size: 16px;
          color: #666 !important;
          position: absolute;
          right: 10px;
          padding-left: 10px;
          cursor: pointer;
        }
      }
      &.out-container__active {
        border: 1px solid #0099ff !important;
        border-right: none !important;
        background: #f7f9ff;
        position: relative;
      }
    }

    .out-container-line {
      position: relative;
      box-sizing: border-box;
      .out-container-cover {
        position: absolute;
        top: 50%;
        left: -60px;
        width: 5px;
        height: 1000px;
        background-color: #fff;
        margin-top: 1px;
      }
      &::before {
        content: "";
        position: absolute;
        left: -60px;
        top: 50%;
        bottom: -1000px;
        border-left: 1.5px solid #e0e0e0;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: -60px;
        width: 60px;
        border-bottom: 1.5px solid #e0e0e0;
        margin-top: -0.5px;
      }
    }

    .right {
      flex: 1;
      .condition-filter {
        height: 100%;
        .custom-analysis-filter {
          height: 100%;
          position: relative;
          overflow: hidden;
          background: #f6f9ff;
          border: 1px solid #0099ff;
          padding: 0 10px 30px 30px;
          .single-analysis {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px 10px 14px 0;
          }
          .out-container2 {
            //   width: 100%;
            background-color: #fff;
            padding: 10px 15px;
            border: 1px solid #e3e3e3;
            margin-top: 15px;
            .out-container-cover {
              position: absolute;
              top: 50%;
              left: -60px;
              width: 5px;
              height: 1000px;
              background-color: #f6f9ff;
              margin-top: 1px;
            }
            .in-relationship-select {
              position: absolute;
              left: -90px;
              top: 50%;
              margin-top: 10px;
            }
            .in-relationship-list {
              overflow: hidden;
              .in-container-wrapper {
                .in-container {
                  background-color: #fff;
                  padding: 10px 0;
                  padding-left: 10px;
                  position: relative;

                  .in-container-item {
                    display: flex;
                    align-items: center;
                    /deep/.item-con {
                      margin-right: 10px;
                      display: flex;
                      align-content: center;
                      // position: relative;
                      .el-date-editor .el-range-separator {
                        width: 40px !important;
                      }

                      .delete-btn {
                        font-size: 16px;
                        color: #666 !important;
                        cursor: pointer;
                        padding-left: 10px;
                      }
                    }
                  }
                }
                .in-container-line {
                  position: relative;
                  margin-left: 100px;
                  &::before {
                    content: "";
                    position: absolute;
                    left: -60px;
                    top: 50%;
                    bottom: -1000px;
                    border-left: 1.5px solid #d6d6d6;
                  }
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -60px;
                    width: 60px;
                    border-bottom: 1.5px solid #d6d6d6;
                    margin-top: -0.5px;
                  }
                  .in-container-cover {
                    position: absolute;
                    top: 50%;
                    left: -60px;
                    width: 5px;
                    height: 1000px;
                    background-color: #fff;
                    margin-top: 1px;
                  }
                }
                .add-btn {
                  margin: 20px 60px 8px 60px;
                  border: 1px dashed #0099ff;
                  color: #0099ff;
                  line-height: 35px;
                  font-size: 14px;
                  text-align: center;
                  cursor: pointer;
                  border-radius: 6px;
                }
              }
            }
          }
          .add-btn-type2 {
            margin: 15px 0;
            border: 1px dashed #d6d6d6;
            color: #999;
            line-height: 60px;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
            border-radius: 6px;
            background-color: #fff;
          }
        }
      }
    }
  }
  .compare-select {
    display: flex;
    align-items: center;
  }
  .delete-btn {
    cursor: pointer;
    color: #0099ff;
  }
}
</style>
